<template>
	<div class="grid grid-cols-1 gap-4 md:gap-8 lg:grid-cols-2">
		<section class="w-full max-w-lg m-auto">
			<AnimatedTransmitImage
				:image="image"
				:hide-animation="hideAnimation"
				:lazy-load="lazyLoad"
			/>
		</section>
		<div
			:class="[
				'w-full m-auto',
				{ 'lg:order-first': position === 'left' }
			]"
		>
			<LearnMoreHelper
				:title="title"
				:description="description"
				:link="link"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { toRefs, reactive } from 'vue'
import type { Position, LearnMoreProps } from '@/components/learnMore/types'
import type { Image } from '@/types/image'

const props = defineProps({
	data: {
		type: Object as PropType<LearnMoreProps>,
		required: true
	},
	position: {
		type: String as PropType<Position>,
		default: 'left'
	},
	lazyLoad: {
		type: Boolean,
		default: false
	}
})

const { position } = toRefs(props)
const { id = '', src = '', srcset = '', alt = '', title = '', description = { type: '', content: [], marks: [], text: '' }, link = '', hideAnimation = false } = reactive(props.data)

const image = computed<Image>(() => {
	return {
		src,
		alt,
		id,
		srcset
	}
})
</script>
