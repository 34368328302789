import type { Position, LearnMoreProps } from '@/components/learnMore/types'

export function mapLearnMore (content: any) {
	return content?.map((section: any) => {
		return {
			id: section.title?.replace(/[' ']/g, '-')?.toLowerCase() || '',
			src: `${section.image.filename}/m/200x200`,
			alt: section.image.alt,
			title: section.title,
			description: section.description,
			link: section.link,
			hideAnimation: section.hideAnimation,
			srcset: `${section.image.filename} 385w, ${section.image.filename}/m/300x300 300w, ${section.image.filename}/m/200x200 200w`
		} as LearnMoreProps
	}) as LearnMoreProps[]
}

export function getPosition (position: Position = 'left', index: number) {
	return index % 2 === 0 ? position : (position === 'left' ? 'right' : 'left')
}

export default { mapLearnMore, getPosition }
