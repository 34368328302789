<template>
	<div class="grid w-full grid-cols-1 gap-4 px-4 text-left md:px-0">
		<h2 class="text-4xl font-bold text-black uppercase xs:text-2xl">
			{{ title }}
		</h2>
		<span class="text-lg text-black" v-html="renderedDescription" />
	</div>
</template>

<script setup lang="ts">
import type { ISbRichtext } from '@storyblok/js'
const props = defineProps({
	title: {
		type: String,
		required: true
	},
	description: {
		type: Object as PropType<ISbRichtext>,
		required: true
	}
	/*
	link: {
		type: String,
		required: true
	}
	*/
})

const { title, description /* link */ } = toRefs(props)

const renderedDescription = computed(() => {
	return renderRichText(description.value)
})
</script>
